import { inject, Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { MapsService } from './shared/services/maps.service';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { FcmService } from './core/services/fcm.service';
import { WebSyncService } from './mirror/web-sync.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  readonly #fcm = inject(FcmService);
  readonly #maps = inject(MapsService);
  readonly #platform = inject(Platform);
  readonly #router = inject(Router);
  readonly #webSync = inject(WebSyncService);
  readonly #zone = inject(NgZone);

  async boot() {
    await this.#webSync.checkAndUpdate();

    this.#fcm.boot();
    this.#maps.bootLibraries('drawing', 'geometry');
    this.bootIcons();
    this.registerListeners();
    await this.#platform.ready();
  }

  protected bootIcons() {
    addIcons(icons);
  }

  protected registerListeners() {
    App.addListener('appUrlOpen', this.urlOpenEventHandler);
    App.addListener('appStateChange', this.stateChangedHandler)
  }

  private urlOpenEventHandler = (event: URLOpenListenerEvent) => {
    this.#zone.run(() => {
      const url = new URL(event.url);
      if (url.pathname) {
        this.#router.navigate([url.pathname], {
          queryParams: {
            ref: url.searchParams.get('ref') ?? '',
          },
        });
      }
    });
  };

  private stateChangedHandler = (event: AppState) => {
    if (event.isActive) {
      this.#webSync.checkAndUpdate();
    }
  }
}
