export const AUTH_STATE_KEY = 'AbsDepot.AuthState';

type ErrorCode =
  | string
  | 'auth/operation-not-allowed'
  | 'auth/popup-closed-by-user';

export const FIREBASE_AUTH_ERRORS: Record<ErrorCode, string> = {
  'auth/operation-not-allowed':
    'Este proveedor no esta disponible. Intente con otro proveedor.',
  'auth/popup-closed-by-user': 'Inicio de sesión cancelado por el usuario.',
};
