import {
  HttpContextToken,
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Allows bypassing the authInterceptor
 */
export const BYPASS_AUTH_INTERCEPTOR = new HttpContextToken(() => false);

/**
 * Intercepts HTTP requests to add authentication token and handle 401/419 errors.
 * @param req The outgoing request object to handle.
 * @param next The next interceptor in the chain, or the backend if no interceptors remain.
 * @returns An Observable of the HTTP event stream.
 */
export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  if (req.context.get(BYPASS_AUTH_INTERCEPTOR)) {
    return next(req);
  }
  return auth.state$.pipe(
    map((state) => state?.token),
    map((token) =>
      req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      }),
    ),
    switchMap((req) => next(req)),
    catchError((response: HttpErrorResponse) => {
      if (response.status === 419 || response.status === 401) {
        auth.logout();
      }
      throw response;
    }),
  );
};
