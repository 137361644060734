import { Injectable } from '@angular/core';

type LibraryName = 'drawing' | 'geometry';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  public async bootLibraries(...libraries: LibraryName[]) {
    const promises = [...new Set(libraries)].map((lib) =>
      google.maps.importLibrary(lib),
    );
    return Promise.all(promises);
  }
}
