import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Preferences } from '@capacitor/preferences';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AUTH_STATE_KEY } from '../../auth/auth.constants';
import { pruneUndefined } from '../../shared/utils/prune-undefined';
import { DeviceData } from '../services/device.service';
import { AuthState } from './auth-state';
import {
  FirebaseSessionRequest,
  RegisterRequestData,
  ResetPasswordRequestData,
} from './auth.models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly #device = inject(DeviceData);
  readonly #http = inject(HttpClient);
  readonly #router = inject(Router);

  readonly #state = new ReplaySubject<AuthState | null>(1);
  readonly state$ = this.#state.asObservable().pipe();
  readonly state = toSignal(this.state$);
  readonly userId = computed(() => this.state()?.userId || 0);

  readonly ref = signal('');

  constructor() {
    inject(Auth);
    this.syncStateFromStorage().then();
    this.state$.pipe(takeUntilDestroyed()).subscribe((state) =>
      Preferences.set({
        key: AUTH_STATE_KEY,
        value: JSON.stringify(state),
      }),
    );
  }

  private async syncStateFromStorage() {
    const { value: authStateString } = await Preferences.get({
      key: AUTH_STATE_KEY,
    });

    const parsedAuthState: AuthState | null = authStateString
      ? JSON.parse(authStateString)
      : null;
    this.#state.next(parsedAuthState);
  }

  async login(email: string, password: string) {
    const url = `${environment.apiUrl}/auth/login`;
    const body = { email, password, device: this.#device.id };
    const request = this.#http.post<AuthState>(url, body);
    const response = await firstValueFrom(request);
    this.#state.next(response);
  }

  async logout() {
    try {
      const { user } = await FirebaseAuthentication.getCurrentUser();
      if (user) {
        await FirebaseAuthentication.signOut();
      }
    } catch (e) {
      console.error(e);
    }
    if (!this.state()?.userId) return;
    this.#state.next(null);
    if (this.#router.url.split('/').some((v) => v === 'auth')) return;
    await this.#router.navigate(['/', 'auth']);
  }

  async register(data: RegisterRequestData) {
    const url = `${environment.apiUrl}/auth/register`;
    const body = { ...data, device: this.#device.id };
    const request = this.#http.post<AuthState>(url, body);
    const response = await firstValueFrom(request);
    this.#state.next(response);
    return response;
  }

  async forgotPassword(email: string) {
    const url = `${environment.apiUrl}/auth/forgot-password`;
    const request = this.#http.post<{ message: string }>(url, { email });
    return await firstValueFrom(request);
  }

  async resetPassword(data: ResetPasswordRequestData) {
    const url = `${environment.apiUrl}/auth/reset-password`;
    const body = { ...data, device: this.#device.id };
    const request = this.#http.post<AuthState>(url, body);
    const response = await firstValueFrom(request);
    this.#state.next(response);
    return response;
  }

  async changePassword(password: string) {
    const url = `${environment.apiUrl}/auth/change-password`;
    const request = this.#http.post<{ message: string }>(url, { password });
    return await firstValueFrom(request);
  }

  async loginWithFirebase(body: FirebaseSessionRequest) {
    const url = `${environment.apiUrl}/auth/firebase/session`;
    const request = this.#http.post<AuthState>(url, pruneUndefined(body));
    const response = await firstValueFrom(request);
    this.#state.next(response);
  }

  linkUserToFirebase(token: string) {
    const url = `${environment.apiUrl}/auth/firebase/link`;
    const request = this.#http.post<{ message: string }>(url, { token });
    return firstValueFrom(request);
  }
}
