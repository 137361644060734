import { inject } from '@angular/core';
import { CanMatchFn, RedirectCommand, Router } from '@angular/router';
import { map, switchMap } from 'rxjs';
import { AppPath } from '../../app.routes';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';

export const hasBeenInvited: CanMatchFn = () => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const user = inject(UserService);

  if (!auth.state()) {
    return true;
  }

  if (user.resource.value()?.data.invited_by) {
    return true;
  }

  return auth.state$.pipe(
    switchMap((state) => user.findById(state!.userId)),
    map((resource) => Boolean(resource.data?.invited_by)),
    map(
      (can) =>
        can ||
        new RedirectCommand(router.createUrlTree(['/', AppPath.Onboarding])),
    ),
  );
};
