import { Injectable, resource } from '@angular/core';
import { MirrorConfig } from './mirror-config.interface';
import { environment } from 'src/environments/environment';
import { tryOrDefault } from '../shared/utils/try-or-default';
import { Preferences } from '@capacitor/preferences';

const MIRROR_FILE_PATH = '/.well-known/mirror.json';
const LAST_UPDATE_KEY = 'Mirror.WebSync.LastUpdate';
const RETRY_COUNT_KEY = 'Mirror.WebSync.RetryCount';
const MAX_RETRY_COUNT = 3;

@Injectable({
  providedIn: 'root',
})
export class WebSyncService {
  readonly lastUpdated = resource({
    loader: () => this.getLastUpdated(),
  });

  readonly getCurrentVersion = async () => Promise.resolve(environment.version);

  readonly getLatestVersion = async () =>
    fetch(MIRROR_FILE_PATH)
      .then((response) => response.json())
      .then((data: MirrorConfig) => data.webSync.version);

  readonly getLastUpdated = async () => {
    const { value } = await Preferences.get({ key: LAST_UPDATE_KEY });
    return value ? (JSON.parse(value) as Date) : undefined;
  };

  readonly setLastUpdated = async (value: Date) => {
    await Preferences.set({
      key: LAST_UPDATE_KEY,
      value: JSON.stringify(value),
    });
  };

  protected readonly canUpdate = async () => {
    const currentRetryCount = await this.getCurrentRetryCount();
    if (currentRetryCount >= MAX_RETRY_COUNT) {
      await this.setCurrentRetryCount(0);
      return false;
    }
    return true;
  };

  protected readonly shouldUpdate = async () => {
    const current = await this.getCurrentVersion();
    const latest = await tryOrDefault({
      value: this.getLatestVersion(),
      fallback: current,
    });

    if (current === latest) {
      return false;
    }
  };

  protected readonly update = async () => {
    await this.setLastUpdated(new Date());
    location.reload();
  };

  readonly checkAndUpdate = async () => {
    const can = await this.canUpdate();
    if (!can) return;

    const should = await this.shouldUpdate();
    if (!should) return;

    await this.update();
  };

  protected readonly getCurrentRetryCount = async () => {
    return tryOrDefault({
      value: Preferences.get({ key: RETRY_COUNT_KEY }).then(({ value }) =>
        parseInt(value ?? JSON.stringify(0)),
      ),
      fallback: MAX_RETRY_COUNT,
    });
  };

  protected readonly setCurrentRetryCount = async (count: number) => {
    const value = JSON.stringify(Math.max(count, 0));
    await Preferences.set({ key: RETRY_COUNT_KEY, value });
  };
}
