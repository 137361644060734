import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AppService } from './app.service';
import { authInterceptor } from './core/auth/auth.interceptor';
import { provideSentry } from './core/providers/sentry.provider';
import { Logger } from './core/services/logger.service';
import { SentryLogger } from './core/services/sentry-logger.service';
import { fireConfig } from './fire.config';
import { alertsInterceptor } from './shared/interceptors/alerts.interceptor';
import { errorsInterceptor } from './shared/interceptors/errors.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'RD$' },
    provideHttpClient(
      withInterceptors([authInterceptor, errorsInterceptor, alertsInterceptor]),
    ),
    fireConfig(environment.firebase),
    provideIonicAngular({ mode: 'md' }),
    provideAppInitializer(() => inject(AppService).boot()),
    provideSentry(),
    {
      provide: Logger,
      useClass: SentryLogger,
    },
  ],
};
