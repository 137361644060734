import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { tap } from 'rxjs';

export const alertsInterceptor: HttpInterceptorFn = (req, next) => {
  const alertController = inject(AlertController);
  return next(req).pipe(
    tap((response) => {
      if ('alert' in response) {
        alertController
          .create({ message: response.alert as string })
          .then((r) => r.present());
      }
    }),
  );
};
