import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth.service';

export const authGuard: CanMatchFn = (_route, _segments) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  return auth.state$.pipe(
    map((state) => Boolean(state) || router.createUrlTree(['/auth'])),
  );
};

export const noAuthGuard: CanMatchFn = (_route, _segments) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  return auth.state$.pipe(
    map((state) =>
      state?.userId ? router.createUrlTree(['/', 'features', 'home']) : true,
    ),
  );
};
