import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class DeviceData {
  #id = '';

  get id() {
    return this.#id;
  }

  constructor() {
    Device.getId().then((result) => (this.#id = result.identifier));
  }
}
