export const tryOrDefault = async <T>(args: {
  value: T | Promise<T> | (() => T | Promise<T>);
  fallback: T;
}) => {
  const { value, fallback } = args;
  try {
    const resolvedValue =
      typeof value === 'function'
        ? await (value as () => T | Promise<T>)()
        : await value;
    return resolvedValue;
  } catch {
    return fallback;
  }
};
