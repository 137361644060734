import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { register } from 'swiper/element/bundle';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './core/services/user.service';

register();

@Component({
  selector: 'app-root',
  template: `
    <ion-app style="background: transparent;">
      <ion-router-outlet />
    </ion-app>
  `,
  imports: [IonApp, IonRouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #auth = inject(AuthService);

  private readonly userService = inject(UserService);

  constructor() {
    this.#activatedRoute.queryParams
      .pipe(takeUntilDestroyed())
      .subscribe((params) => {
        const ref = params['ref'];
        if (!ref) return;
        this.#auth.ref.set(ref);
      });
  }
}
