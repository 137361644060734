import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { hasMessage } from '../utils/assertions/has-message';

export const errorsInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((response: HttpErrorResponse) => {
      return throwError(() => handleError(response));
    }),
  );
};

const handleError = (response: HttpErrorResponse) => {
  const { status, error } = response;
  if (hasMessage(error)) {
    return { status, message: error.message };
  }

  let message = 'Ha ocurrido un error';
  switch (status) {
    case 422:
      message = Object.values(error.errors).flat().join(', ');
      break;
    case 402:
      message = error.message;
      break;
    case 403:
      message = 'No tiene permiso para realizar esta acción.';
      break;
    case 404:
      message = 'El recurso solicitado no fue encontrado.';
      break;
    case 500:
      message = 'Hubo un error en el servidor, intente mas tarde.';
      break;
    default: {
      if (hasMessage(error)) {
        message = error.message;
      }
      break;
    }
  }
  console.log({ message: message, status: status, response });
  return { message, status };
};
